<template>
  <CBox :mt="mt">
    <CButton
      v-if="!iconPosition"
      :size="size"
      :disabled="disabled"
      bg="activeMenu"
      color="white"
      :_hover="{ bg: 'activeMenu' }"
      @click="$emit('handleClick')"
    >
      <CImage
        v-if="icon"
        :src="require(`@/assets/icon/${icon}`)"
        w="18px"
        pr="5px"
      />
      <CText fontFamily="Montserrat" color="white" fontSize="14px" :px="px">{{
        label
      }}</CText>
    </CButton>
    <CButton
      v-if="iconPosition == 'left'"
      :left-icon="icon"
      :size="size"
      :disabled="disabled"
      bg="activeMenu"
      color="white"
      :_hover="{ bg: 'activeMenu' }"
    >
      {{ label }}
    </CButton>
    <CButton
      v-if="iconPosition == 'right'"
      :right-icon="icon"
      :size="size"
      :disabled="disabled"
      bg="activeMenu"
      color="white"
      :_hover="{ bg: 'activeMenu' }"
    >
      {{ label }}
    </CButton>
  </CBox>
</template>

<script>
import { CButton, CBox, CImage, CText } from "@chakra-ui/vue";

export default {
  name: "ButtonBlue",
  components: {
    CButton,
    CBox,
    CImage,
    CText,
  },
  props: {
    label: {
      type: String,
      default: "Button",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "md",
    },
    iconPosition: {
      type: String,
      default: "",
    },
    mt: {
      type: String,
      default: "0px",
    },
    px: {
      type: String,
      default: "0px",
    },
  },
};
</script>
