<template>
  <CBox>
    <Loading :active="isLoading" />
    <CBox mt="30px">
      <BackHeader />
      <CGrid
        :templateColumns="['12fr', '12fr', '7fr 3fr', '7fr 3fr']"
        :gap="[3, 3, 6, 6]"
        mb="8"
      >
        <CBox>
          <CBox
            bg="white"
            minH="100px"
            borderRadius="5px"
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
          >
            <CBox
              py="20px"
              mx="20px"
              justifyContent="space-between"
              display="flex"
            >
              <CText
                fontFamily="Montserrat"
                fontStyle="normal"
                fontSize="16px"
                fontWeight="600"
                color="activeMenu"
              >
                {{ currentAppType.name }}
              </CText>
              <CBox justifyContent="space-between" display="inline-flex">
                <CIcon name="filter" mt="2" mr="3" color="activeMenu" />
                <CSelect v-model="filterType" size="sm">
                  <option :value="null">-- Semua grup --</option>
                  <option
                    v-for="(r, i) in hashtagsGroup"
                    :key="i"
                    :value="r.id"
                  >
                    {{ r.title }}
                  </option>
                </CSelect>
              </CBox>
            </CBox>
            <CBox bg="bgSocialBadge" h="37px">
              <CGrid templateColumns="1fr 3fr 3fr 2fr" :gap="2" h="inherit">
                <CBox align="center" py="auto" my="auto">
                  <CText
                    fontFamily="Montserrat"
                    fontStyle="normal"
                    fontSize="11px"
                    fontWeight="bold"
                    color="headerTable"
                  >
                    NO
                  </CText>
                </CBox>
                <CBox py="auto" my="auto">
                  <CText
                    fontFamily="Montserrat"
                    fontStyle="normal"
                    fontSize="11px"
                    fontWeight="bold"
                    color="headerTable"
                  >
                    TAGS
                  </CText>
                </CBox>
                <CBox py="auto" my="auto">
                  <CText
                    fontFamily="Montserrat"
                    fontStyle="normal"
                    fontSize="11px"
                    fontWeight="bold"
                    color="headerTable"
                  >
                    GROUP
                  </CText>
                </CBox>
                <CBox py="auto" my="auto">
                  <CText
                    fontFamily="Montserrat"
                    fontStyle="normal"
                    fontSize="11px"
                    fontWeight="bold"
                    color="headerTable"
                  >
                    CHALLENGERS
                  </CText>
                </CBox>
              </CGrid>
            </CBox>
            <CBox>
              <CBox h="70px" v-for="(v, i) in hashtagsList" :key="i">
                <CGrid templateColumns="1fr 3fr 3fr 2fr" :gap="2" h="inherit">
                  <CBox align="center" py="auto" my="auto">
                    <CText
                      fontFamily="Montserrat"
                      fontStyle="normal"
                      fontSize="14px"
                      fontWeight="bold"
                    >
                      {{ i + 1 }}
                    </CText>
                  </CBox>
                  <CBox py="auto" my="auto">
                    <CText
                      fontFamily="Montserrat"
                      fontStyle="normal"
                      fontSize="14px"
                      fontWeight="bold"
                      class="pointer"
                      color="activeMenu"
                      @click.native="
                        $router.push({
                          name: 'Leaderboard',
                          params: { id: $route.params.id },
                          query: { tag: v.name },
                        })
                      "
                    >
                      #{{ v.name }}
                    </CText>
                  </CBox>
                  <CBox py="auto" my="auto">
                    <CText
                      fontFamily="Montserrat"
                      fontStyle="normal"
                      fontSize="14px"
                      fontWeight="bold"
                    >
                      {{ v.hashtag_group.title || "-" }}
                    </CText>
                  </CBox>
                  <CBox py="auto" my="auto">
                    <CText
                      fontFamily="Montserrat"
                      fontStyle="normal"
                      fontSize="14px"
                      fontWeight="bold"
                    >
                      {{ v.challengers }}
                    </CText>
                  </CBox>
                </CGrid>
              </CBox>
              <CBox
                v-if="hashtagsPage * hashtagsLimit < hashtagsTotal"
                py="20px"
                align="center"
              >
                <CSpinner v-if="isLoadingMore" />
                <CText
                  v-else
                  fontFamily="Montserrat"
                  fontSize="16px"
                  fontWeight="500"
                  color="activeMenu"
                  class="pointer"
                  @click.native="loadMore()"
                >
                  Load More ...
                </CText>
              </CBox>
            </CBox>
          </CBox>
        </CBox>
        <CBox>
          <CBox
            bg="white"
            :mb="['0', '0', '40px', '40px']"
            minH="100px"
            borderRadius="5px"
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
          >
            <CBox px="20px" py="20px">
              <CBox pt="30px">
                <CImage
                  px="20px"
                  :src="require('@/assets/img/illustration-programming.png')"
                />
              </CBox>
              <CBox pt="40px" align="center">
                <CText
                  fontFamily="Montserrat"
                  fontStyle="normal"
                  fontSize="20px"
                  fontWeight="700"
                  color="fontMain"
                  pb="10px"
                  >Ukur Kemampuan Programming Kamu Disini
                </CText>
                <CText
                  fontFamily="Montserrat"
                  fontStyle="normal"
                  fontSize="14px"
                  fontWeight="400"
                  color="fontMain"
                  pb="10px"
                  >Kini Anda dapat mengasah kemampuan programming sesungguhnya
                  di Gethired Devcode
                </CText>
                <ButtonBlue @click.native="openBlog()" label="Explore" />
              </CBox>
            </CBox>
          </CBox>
        </CBox>
      </CGrid>
    </CBox>
  </CBox>
</template>

<script>
import Swal from "sweetalert2";
import ButtonBlue from "@/components/button/ButtonBlue.vue";
import { GET_APP_TYPE } from "@/store/app-types.module";
import {
  GET_HASHTAGS_LIST,
  GET_HASHTAGS_GROUP,
  HANDLE_ACTIVE_GROUP,
  SET_HASHTAGS_LOADING,
  LOADMORE_HASHTAGS,
  RESET_HASHTAGS_STATE,
} from "@/store/hashtag.module";
import Loading from "vue-loading-overlay";
import BackHeader from "@/components/BackHeader";
import config from "@/ConfigProvider";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  metaInfo() {
    return {
      title: "Hashtag",
      titleTemplate: "Devcode - %s",
    };
  },
  components: {
    ButtonBlue,
    Loading,
    BackHeader,
  },
  computed: {
    currentAppType() {
      return this.$store.getters.currentAppType;
    },
    hashtagsGroup() {
      return this.$store.getters.hashtagsGroup;
    },
    hashtagsList() {
      return this.$store.getters.hashtagsList;
    },
    hashtagsPage() {
      return this.$store.getters.hashtagsPage;
    },
    hashtagsLimit() {
      return this.$store.getters.hashtagsLimit;
    },
    hashtagsTotal() {
      return this.$store.getters.hashtagsTotal;
    },
    isLoading() {
      return this.$store.getters.isLoadingHashtags;
    },
    isLoadingMore() {
      return this.$store.getters.isLoadingMoreHashtags;
    },
    filterType: {
      get() {
        return this.$store.getters.activeGroup;
      },
      set(value) {
        if (!this.isLoading) this.$store.dispatch(HANDLE_ACTIVE_GROUP, value);
      },
    },
  },
  data() {
    return {
      blogUrl: config.value("DEVCODE_BLOG_URL"),
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    openBlog() {
      window.open(this.blogUrl, "_blank");
    },
    goBack() {
      window.history.back();
    },
    loadMore() {
      this.$store.dispatch(LOADMORE_HASHTAGS);
    },
    async getData() {
      try {
        this.$store.commit(RESET_HASHTAGS_STATE);
        this.$store.commit(SET_HASHTAGS_LOADING, true);
        await this.$store.dispatch(GET_APP_TYPE, this.$route.params.id),
          await Promise.all([
            this.$store.dispatch(GET_HASHTAGS_GROUP),
            this.$store.dispatch(GET_HASHTAGS_LIST),
          ]);
      } catch (err) {
        Swal.fire({
          title: "Oops . .",
          text: err.message,
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
      } finally {
        this.$store.commit(SET_HASHTAGS_LOADING, false);
      }
    },
  },
};
</script>
